var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.appointments && _vm.appointments.length > 0
      ? _c(
          "div",
          _vm._l(_vm.appointments, function (item, i) {
            return _c(
              "div",
              { key: i },
              _vm._l(item.timeSheetDetails, function (itemDetails, i) {
                return _c(
                  "v-card",
                  {
                    key: i,
                    staticClass:
                      "p-3 bg-white rounded my-5 d-flex flex-row align-items-center",
                    attrs: { flat: "" },
                  },
                  [
                    itemDetails.userAppointment.clientUserPic
                      ? _c("v-avatar", { attrs: { size: "50" } }, [
                          _c("img", {
                            attrs: {
                              src: itemDetails.userAppointment.clientUserPic,
                              alt: "Appointment-logo",
                            },
                          }),
                        ])
                      : _c("b-icon", {
                          staticClass: "bg-light pa-4 rounded-circle",
                          attrs: {
                            icon: "person",
                            width: "50",
                            height: "50",
                            variant: "info",
                          },
                        }),
                    _c("div", { staticClass: "ms-3" }, [
                      _c("span", { staticClass: "d-block f15 fw-bold" }, [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("APPOINTMENT")) + " "),
                        ]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(itemDetails.userAppointment.sessionType)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("span", { staticClass: "d-block f13" }, [
                        _vm._v(_vm._s(item.doctorFullName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between f13" },
                        [
                          _c("span", { staticClass: "grey--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("moment")(
                                    item.valueDate,
                                    "dddd , jD jMMMM jYYYY"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("span", { staticClass: "mx-1 grey--text" }, [
                            _vm._v("|"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "grey--text",
                              attrs: { dir: "ltr" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(itemDetails.fromTime) +
                                  " - " +
                                  _vm._s(itemDetails.toTime) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", [
                        _c("p", { staticClass: "f15 fw-bold py-0 my-0" }, [
                          _vm._v(_vm._s(_vm.$t("ShowDescriptionUser"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              itemDetails.description
                                ? itemDetails.description
                                : "-"
                            )
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            class: itemDetails.userAppointment.paid
                              ? "text-success"
                              : "text-danger",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.consultantAmount.toLocaleString()) +
                                " " +
                                _vm._s(_vm.$t("RIAL")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-2 ms-0" },
                        [
                          !itemDetails.userAppointment.paid
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-lg",
                                  attrs: { elevation: "0", color: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePay(item, itemDetails)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("Pay")) + " ")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    text: "",
                                    elevation: "0",
                                    color: "success",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("PAID")))]
                              ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "500" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ms-2 rounded-lg",
                                                attrs: {
                                                  text: "",
                                                  elevation: "0",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "USER_APPOINTMENT_CANCEL"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.cancelAppointment,
                                callback: function ($$v) {
                                  _vm.cancelAppointment = $$v
                                },
                                expression: "cancelAppointment",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "text-center pa-4" },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "f16 font-weight-bold d-block",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("CANCEL_RESERVED_APPOINTMENT")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "f14 p-2 font-weight-light",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "Please contact the support team for cancellation"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            color: "warning",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.cancelAppointment = false
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("I Understand")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              1
            )
          }),
          0
        )
      : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
          _vm._v(" " + _vm._s(_vm.$t("No Appointments found")) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }